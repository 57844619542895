import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../style/kwhupdate.css';

const UpdateHourlyKwh = () => {
    const [date, setDate] = useState('');
    const [hour, setHour] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [roomNo, setRoomNo] = useState('');
    const [hostelId, setHostelId] = useState('');
    const [whValue, setWhValue] = useState('');
    const [message, setMessage] = useState('');
    const [confirmUpdate, setConfirmUpdate] = useState(false);
    const [isWhEditable, setIsWhEditable] = useState(false);
    const [phase, setPhase] = useState(1); // Default phase

    const fetchWhValue = async () => {
        if (!date || !hour || !deviceId || !roomNo || !hostelId) return;

        try {
            const response = await axios.post('https://sawangi.hetadatain.com/api/fetch-wh-value', {
                date,
                hour,
                device_id: deviceId,
                room_no: roomNo,
                hostel_id: hostelId,
            });

            if (response.data) {
                const { wh_1, wh_2, wh_3, phase } = response.data;
                setPhase(phase);
                setWhValue(phase === 1 ? wh_1 : phase === 2 ? wh_2 : phase === 3 ? wh_3 : '');
            } else {
                setWhValue('');
            }
        } catch (error) {
            console.error('Error fetching WH value:', error);
            setMessage('Error fetching WH value');
        }
    };

    useEffect(() => {
        fetchWhValue();
    }, [date, hour, deviceId, roomNo, hostelId]);

    const handleConfirmUpdate = () => {
        setConfirmUpdate(true);
        setIsWhEditable(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestBody = {
            date,
            hour,
            device_id: deviceId,
            hostel_id: hostelId,
            wh_1: phase === 1 ? whValue : null,
            wh_2: phase === 2 ? whValue : null,
            wh_3: phase === 3 ? whValue : null,
            phase,
            room_no: roomNo, // Include room_no in request body
        };

        console.log('Request Body:', requestBody); // Log the request body

        try {
            const response = await axios.post('https://sawangi.hetadatain.com/api/update-hourly-kwh', requestBody);
            setMessage(response.data.message);
        } catch (error) {
            console.error('Error updating WH value:', error.response.data);
            if (error.response && error.response.data.errors) {
                setMessage('Validation errors: ' + JSON.stringify(error.response.data.errors));
            } else {
                setMessage('An error occurred during update');
            }
        }
    };

    return (
        <div className="form-container">
            <h1>Update Hourly KWh</h1>
            <form onSubmit={handleSubmit} className="update-form">
                <div className="form-group">
                    <label>Date:</label>
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Hour:</label>
                    <input
                        type="text"
                        value={hour}
                        onChange={(e) => setHour(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Device ID/Meter No:</label>
                    <input
                        type="text"
                        value={deviceId}
                        onChange={(e) => setDeviceId(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Room No:</label>
                    <input
                        type="text"
                        value={roomNo}
                        onChange={(e) => setRoomNo(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Hostel ID:</label>
                    <input
                        type="text"
                        value={hostelId}
                        onChange={(e) => setHostelId(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Meter Reading:</label>
                    <input
                        type="number"
                        value={whValue || ''}
                        onChange={(e) => setWhValue(e.target.value)}
                        readOnly={!isWhEditable}
                    />
                </div>

                {whValue && !confirmUpdate && (
                    <div className="confirmation">
                        <p>Do you want to change the WH value?</p>
                        <button type="button" onClick={handleConfirmUpdate}>Yes</button>
                        <button type="button" onClick={() => setConfirmUpdate(false)}>No</button>
                    </div>
                )}
                
                {confirmUpdate && <button type="submit" className="update-button">Update</button>}
            </form>
            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default UpdateHourlyKwh;
